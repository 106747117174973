import React, { useState, useEffect } from 'react'
import axios from 'axios'
import ReactModal from 'react-modal'
import SideBar from './components/Sidebar'
import AdminModal from './modals/AdminModal'
import { Switch as Switching, Route, Redirect, useLocation } from 'react-router-dom'
import Brightness4Icon from '@material-ui/icons/Brightness4'
import Switch from '@material-ui/core/Switch'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import styled from 'styled-components'
import SettingsIcon from '@material-ui/icons/Settings'
import { MainLayout } from './styles/Layouts'
import { useDispatch } from 'react-redux'
import { changeName, changeToken } from './app/features/authSlice'
import HomeNonePage from './pages/HomeNonePage'
import { useCookies } from 'react-cookie'
import { API } from './data/Api'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  LA_MAISON_I,
  LA_MAISON_II,
  LA_MAISON_III,
  LA_MAISON_IV,
  LA_MAISON_V,
  LA_MAISON_VI,
  LA_MAISON_VII,
  LA_MAISON_VIII,
  LA_MAISON_IX,
  LA_MAISON_X,
  LA_MAISON_XI,
  LA_MAISON_XII,
  LES_MAISONS,
  LES_MAISONS_RAPPORT_I_ET_VII,
  LES_MAISONS_RAPPORT_II_ET_VIII,
  LES_MAISONS_RAPPORT_III_ET_IX,
  LES_MAISONS_RAPPORT_IV_ET_X,
  LES_MAISONS_RAPPORT_V_ET_XI,
  LES_MAISONS_RAPPORT_VI_ET_XII
} from './data/Liens'
import HomePage from './pages/HomePage'
import InscriptionPage from './pages/InscriptionPage'
import AboutPage from './pages/AboutPage'
import RechercheDominantePage from './pages/RechercheDominantePage'
import LesMaisonsPage from './pages/LesMaisonsPage'
import LesAstresPage from './pages/LesAstresPage'
import HomeLoginPage from './pages/HomeLoginPage'
import LeTirageAstrologiquePage from './pages/LeTirageAstrologiquePage'
import LaMaisonPage from './pages/LaMaisonPage'
import ThemePage from './pages/ThemePage'
import LeTirageSephirothPage from './pages/LeTirageSephirothPage'
import SaisieThemePage from './pages/SaisieThemePage'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AnyReactModal: any = ReactModal

function App(): JSX.Element {
  const [theme, setTheme] = useState('dark-mode')
  const [checked, setChecked] = useState(false)
  const [isNavToggle, setNavToggle] = useState(false)
  const [setting, setSetting] = useState(false)
  const [modalIsOpen, setIsOpen] = useState(false)
  const [displayNone, setDisplayNone] = useState('')
  const [cookies, setCookie] = useCookies(['user', 'token'])
  const dispatch = useDispatch()
  const location = useLocation()

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  useEffect(() => {
    setDisplayNone('none')
    if (location.pathname === '/inscription') {
      setCookie('token', '')
      setDisplayNone('none')
      setDisplayNone('ok')
    } else if (cookies['token'] === 'undefined') {
      setTimeout(() => {
        openModal()
      }, 500)
    } else {
      axios.request({
        method: 'get',
        url: API + 'check_auth',
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + cookies['token'] }
      })
        .then((response) => {
          dispatch(changeToken('Bearer ' + cookies['token'])) // redux
          dispatch(changeName(response.data.status.data.user.name)) // redux
          setDisplayNone('ok')
        }).catch((err) => {
          console.error(err)
          toast.error(<p>Erreur de chargement des données "check_auth":<br />{err.response.data}</p>)
          setCookie('token', '')
          setDisplayNone('none')
          openModal()
        })
    }
    document.documentElement.className = theme
  }, [theme])

  // TODO chantier
  useEffect(() => {
    if (location.pathname === '/confirmation-inscription') {
      openModal()
    }
    if (location.pathname === '/inscription') {
      setTimeout(() => {
        setDisplayNone('none')
      }, 500)
      setTimeout(() => {
        setDisplayNone('ok')
      }, 500)
    }
  }, [location.pathname])

  const themeMode = () => {
    if (theme === 'light-mode') {
      setTheme('dark-mode')
      setChecked(false)
    } else {
      setTheme('light-mode')
      setChecked(true)
    }
  }

  const jwtTokenChange = (e: string) => {
    if (e === 'ok') {
      setDisplayNone('ok')
    } else if (e === 'err') {
      setDisplayNone('err')
    } else {
      setDisplayNone('none')
    }
  }

  return (
    <div className="App" id='TOP'>
      <ToastContainer theme={theme === 'light-mode' ? 'light' : 'dark'}/>
      <AnyReactModal
        isOpen={modalIsOpen}
        contentLabel="onRequestClose Example"
        onRequestClose={closeModal}
        className="Modal"
        overlayClassName="Overlay"
        closeTimeoutMS={2000}
        ariaHideApp={false}
      >
        <AdminModal closeModal={closeModal} jwtTokenChange={jwtTokenChange}/>
      </AnyReactModal>
      <SideBar
        isNavToggle={isNavToggle}
        setNavToggle={setNavToggle}
        theme={theme}
      />

      {/* Setting Wheel for light and dark theme */}
      <div className='setting-wheel no-print'>
        <IconButton onClick={() => setSetting(!setting)}>
          <SettingsIcon/>
        </IconButton>
      </div>

      {/* Toggle Button for light and dark theme */}
      <div>
        <div className={setting ? 'light-dark-mode theme' : 'light-dark-mode'}>
          <div className='left-content'>
            <Brightness4Icon/>
          </div>
          <div className='right-content'>
            <Switch
              value=''
              color='default'
              checked={checked}
              inputProps={{ 'aria-label': '' }}
              size='medium'
              onClick={themeMode}
            />
          </div>
        </div>
      </div>

      {/* Toggler Button for navbar */}
      <div className='hamburger-bar'>
        <IconButton onClick={() => setNavToggle(!isNavToggle)}>
          <MenuIcon/>
        </IconButton>
      </div>

      {/* Main Section Start */}
      <MainSectionStyled>
        {
          /* eslint-disable multiline-ternary */
          /* eslint-disable operator-linebreak */
          displayNone === 'err' ? <HomeLoginPage /> : displayNone === 'none' ?
            <HomeNonePage /> : <MainLayout>
              <Switching>
                <Route path='/' exact> <HomePage/> </Route>
                <Route path='/inscription' exact> <InscriptionPage/> </Route>
                <Route path='/confirmation-incription' exact> <HomePage/> </Route>
                <Route path={LES_MAISONS} exact> <LesMaisonsPage theme={theme}/> </Route>
                <Route path={LES_MAISONS_RAPPORT_I_ET_VII} exact>
                  <LaMaisonPage
                    theme={theme}
                    titreCall='RAPPORT_DES_MAISONS_I_ET_VII'
                    lien={LES_MAISONS_RAPPORT_I_ET_VII}
                  />
                </Route>
                <Route path={LES_MAISONS_RAPPORT_II_ET_VIII} exact>
                  <LaMaisonPage
                    theme={theme}
                    titreCall='RAPPORT_DES_MAISONS_II_ET_VIII'
                    lien={LES_MAISONS_RAPPORT_II_ET_VIII}
                  />
                </Route>
                <Route path={LES_MAISONS_RAPPORT_III_ET_IX} exact>
                  <LaMaisonPage
                    theme={theme}
                    titreCall='RAPPORT_DES_MAISONS_III_ET_IX'
                    lien={LES_MAISONS_RAPPORT_III_ET_IX}
                  />
                </Route>
                <Route path={LES_MAISONS_RAPPORT_IV_ET_X} exact>
                  <LaMaisonPage
                    theme={theme}
                    titreCall='RAPPORT_DES_MAISONS_IV_ET_X'
                    lien={LES_MAISONS_RAPPORT_IV_ET_X}
                  />
                </Route>
                <Route path={LES_MAISONS_RAPPORT_V_ET_XI} exact>
                  <LaMaisonPage
                    theme={theme}
                    titreCall='RAPPORT_DES_MAISONS_V_ET_XI'
                    lien={LES_MAISONS_RAPPORT_V_ET_XI}
                  />
                </Route>
                <Route path={LES_MAISONS_RAPPORT_VI_ET_XII} exact>
                  <LaMaisonPage
                    theme={theme}
                    titreCall='RAPPORT_DES_MAISONS_VI_ET_XII'
                    lien={LES_MAISONS_RAPPORT_VI_ET_XII}
                  />
                </Route>
                <Route path={LA_MAISON_I} exact>
                  <LaMaisonPage theme={theme} titreCall='MAISON_I' lien={LA_MAISON_I}/>
                </Route>
                <Route path={LA_MAISON_II} exact>
                  <LaMaisonPage theme={theme} titreCall='MAISON_II' lien={LA_MAISON_II}/>
                </Route>
                <Route path={LA_MAISON_III} exact>
                  <LaMaisonPage theme={theme} titreCall='MAISON_III' lien={LA_MAISON_III}/>
                </Route>
                <Route path={LA_MAISON_IV} exact>
                  <LaMaisonPage theme={theme} titreCall='MAISON_IV' lien={LA_MAISON_IV}/>
                </Route>
                <Route path={LA_MAISON_V} exact>
                  <LaMaisonPage theme={theme} titreCall='MAISON_V' lien={LA_MAISON_V}/>
                </Route>
                <Route path={LA_MAISON_VI} exact>
                  <LaMaisonPage theme={theme} titreCall='MAISON_VI' lien={LA_MAISON_VI}/>
                </Route>
                <Route path={LA_MAISON_VII} exact>
                  <LaMaisonPage theme={theme} titreCall='MAISON_VII' lien={LA_MAISON_VII}/>
                </Route>
                <Route path={LA_MAISON_VIII} exact>
                  <LaMaisonPage theme={theme} titreCall='MAISON_VIII' lien={LA_MAISON_VIII}/>
                </Route>
                <Route path={LA_MAISON_IX} exact>
                  <LaMaisonPage theme={theme} titreCall='MAISON_IX' lien={LA_MAISON_IX}/>
                </Route>
                <Route path={LA_MAISON_X} exact>
                  <LaMaisonPage theme={theme} titreCall='MAISON_X' lien={LA_MAISON_X}/>
                </Route>
                <Route path={LA_MAISON_XI} exact>
                  <LaMaisonPage theme={theme} titreCall='MAISON_XI' lien={LA_MAISON_XI}/>
                </Route>
                <Route path={LA_MAISON_XII} exact>
                  <LaMaisonPage theme={theme} titreCall='MAISON_XII' lien={LA_MAISON_XII}/>
                </Route>
                <Route path='/recherche-dominante' exact> <RechercheDominantePage theme={theme}/> </Route>
                <Route path='/les-astres' exact> <LesAstresPage theme={theme}/> </Route>
                <Route path='/le-tirage-sephiroth' exact> <LeTirageSephirothPage theme={theme}/> </Route>
                <Route path='/le-tirage-astrologique' exact> <LeTirageAstrologiquePage theme={theme}/> </Route>
                <Route path='/saisie-theme' exact> <SaisieThemePage/> </Route>
                <Route path='/theme' exact> <ThemePage theme={theme}/> </Route>
                <Route path='/about' exact> <AboutPage/> </Route>
                <Redirect to='/'/>
              </Switching>
            </MainLayout>
          /* eslint-enable multiline-ternary */
          /* eslint-enable operator-linebreak */
        }
      </MainSectionStyled>
    </div>
  )
}

const MainSectionStyled = styled.main`
  margin-left: 16.3rem;
  min-height: 100vh;
  @media screen and (max-width: 1200px) {
    margin-left: 0;
  }
`

export default App
