import React, { useEffect, useState } from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import styled from 'styled-components'
import { AstroLayout } from '../styles/AstroLayout'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios'
import reactStringReplace from 'react-string-replace'
import { useSelector } from 'react-redux'
import { selectToken } from '../app/features/authSlice'
import { API } from '../data/Api'
import { ContenuInterface, LiensInterface, TexteInterface } from '../interfaces/v1/Contenu'

type DbTexteProps = {
  titreCall: string
  lien: string
  theme: string
}

function DbTexte({ titreCall, lien, theme }: DbTexteProps): JSX.Element {
  const [db06, setDb06] = useState<TexteInterface>({
    titre: '',
    contenu: ''
  })
  const [db04, setDb04] = useState<LiensInterface[]>([])
  const token = useSelector(selectToken)

  useEffect(() => {
    if (token !== '') {
      httpGetDb06(titreCall)
    }
  }, [])

  const httpGetDb06 = async(titre: string) => {
    axios.request({
      method: 'get',
      url: API + 'contenu/index',
      params: {
        titre_call: titre,
        lien
      },
      headers: { 'Content-Type': 'application/json', Authorization: token }
    }).then(async(response) => {
      const data = await response
      const contenu: ContenuInterface = data.data
      setDb06(contenu.texte)
      setDb04(contenu.liens)
    }).catch((err) => {
      console.error(err)
      toast.error(<p>Erreur de chargement des données "contenu/index":<br />{err.message}</p>)
    })
  }

  const findLinkForWord = (word: string, data: LiensInterface[]) => {
    // Parcourez chaque objet dans le tableau liens.
    for (let i = 0; i < data.length; i++) {
      // Si le mot de l'objet correspond au mot recherché.
      if (data[i].mot === word) {
        // Retournez le lien correspondant.
        return data[i].db04_lien.lien + data[i].db04_lien.ancre
      }
    }

    // Si aucun lien n'a été trouvé pour le mot, retournez null.
    return null
  }

  const findMotFinalForWord = (word: string, data: LiensInterface[]) => {
    // Parcourez chaque objet dans le tableau liens.
    for (let i = 0; i < data.length; i++) {
      // Si le mot de l'objet correspond au mot recherché.
      if (data[i].mot === word) {
        // Retournez le lien correspondant.
        return data[i].mot_final
      }
    }

    // Si aucun lien n'a été trouvé pour le mot, retournez null.
    return null
  }

  const contenu = (texte: string) => {
    // eslint-disable-next-line no-useless-escape
    const regexOrange = /(\#.*?\#)/gm
    const regexOrangeLiens = /(\$.*?\$)/gm
    const regexBr = /[\r\n]/g
    // Match URLs
    let texteRemplacement = reactStringReplace(texte, regexOrange, (match, i) => {
      const regexWithoutHashTag = /#(?!\s)/g
      const texteSansHashTag = match.replace(regexWithoutHashTag, '')
      return (
        <span className="span-color" key={texteSansHashTag + i}>{texteSansHashTag}</span>
      )
    })
    texteRemplacement = reactStringReplace(texteRemplacement, regexOrangeLiens, (match, i) => {
      const regexWithoutDollar = /\$(?!\s)/g
      const texteSansDollar = match.replace(regexWithoutDollar, '')
      const lien = findLinkForWord(texteSansDollar, db04)
      const motFinal = findMotFinalForWord(texteSansDollar, db04)
      if (lien === null || lien === '') {
        return (
          <span className="span-color" key={texteSansDollar + i}>{texteSansDollar}</span>
        )
      } else {
        return (
          <span className="span-color" key={texteSansDollar + i}>
            <Link to={lien} >{motFinal === null ? texteSansDollar : motFinal}</Link>
          </span>
        )
      }
    })
    texteRemplacement = reactStringReplace(texteRemplacement, regexBr, (match, i) => {
      return (
        <span key={match + i}><br /><br /></span>
      )
    })
    return (<p>{texteRemplacement}</p>)
  }

  return (
    <AstroLayout>
      <DbTexteStyled>
        <ToastContainer theme={theme === 'light-mode' ? 'light' : 'dark'}/>
        <h4>{db06.titre}</h4>
        {contenu(db06.contenu)}
        <br />
      </DbTexteStyled>
    </AstroLayout>
  )
}

const DbTexteStyled = styled.div`
`

export default DbTexte
