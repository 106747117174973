import React, { useEffect, useState } from 'react'
import Title from '../components/Title'
import { InnerLayout } from '../styles/Layouts'
import { AstroLayout } from '../styles/AstroLayout'
import Loading from '../components/Loading'
import DbTexte from '../components/DbTexte'
import LesMaisonsSchemaBinaireOppositions from '../components/LesMaisonsSchemaBinaireOppositions'
import ZodiacSphereLocale from '../components/ZodiacSphereLocale'

type LaMaisonPageProps = {
  theme: string
  titreCall: string
  lien: string
}

function LaMaisonPage({ theme, titreCall, lien }: LaMaisonPageProps): JSX.Element {
  const [swLoaded, setSwLoaded] = useState(false)

  useEffect(() => {
    setTimeout(function() {
      setSwLoaded(true)
    }, 100)
  }, [])

  // watcher
  useEffect(() => {
    setSwLoaded(false)
    setTimeout(function() {
      setSwLoaded(true)
    }, 100)
  }, [titreCall])

  return (
    !swLoaded ? <Loading /> : <AstroLayout>
      {/* Les maisons Here */}
      <Title title={'Les'} span={'maisons'}/>
      {/* Les maisons Here */}

      {/* Les douze Maisons */}
      <InnerLayout className="astro-section">
        <div className="info-part" id="DEFINITION">
          <DbTexte
            titreCall={titreCall}
            lien={lien}
            theme={theme}
          />
        </div>
        <ZodiacSphereLocale theme={theme} />
      </InnerLayout>
      {/* Fin Les douze Maisons */}
      {/* Tableau Schéma binaire */}
      <LesMaisonsSchemaBinaireOppositions />
      {/* Fin Tableau Schéma binaire */}
    </AstroLayout>
  )
}

export default LaMaisonPage
