import React, { useEffect, useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import Title from '../components/Title'
import { InnerLayout } from '../styles/Layouts'
import { AstroLayout } from '../styles/AstroLayout'
import AstreTableADark from '../images/Svg/astres-table-a-dark.svg'
import AstreTableALight from '../images/Svg/astres-table-a-light.svg'
// import AstreTableBDark from '../images/Svg/astres-table-b-dark.svg'
// import AstreTableBLight from '../images/Svg/astres-table-b-light.svg'
import {
  ASTRE,
  SOLEIL,
  LUNE,
  MARS,
  VENUS,
  MERCURE,
  CERES,
  JUPITER,
  SATURNE,
  URANUS,
  NEPTUNE,
  PLUTON
} from '../data/Astres'
import { API } from '../data/Api'
import Loading from '../components/Loading'

type LesAstresPageProps = {
  theme: string
}

function LesAstresPage({ theme }: LesAstresPageProps): JSX.Element {
  const [astres, setAstres] = React.useState([{ nom: '', svg: '', id_swe: 0 }])
  const [swLoaded, setSwLoaded] = useState(false)

  useEffect(() => {
    setTimeout(function() {
      setSwLoaded(true)
    }, 100)
  }, [])

  const httpGetAstres = async() => {
    const url = API + 'astres/svg'
    const response = await axios.get(url)
    const data = await response.data
    setAstres(data)
  }

  useEffect(() => {
    httpGetAstres()
  }, [])

  return (
    !swLoaded ? <Loading /> : <LesAstresStyled>
      <AstroLayout>
        {/* Les astres Here */}
        <Title title={'Les'} span={'astres'}/>
        {/* Les astres Here */}
        {/* Couple d'astres */}
        <InnerLayout className="astro-section">
          <img
            className="image-part"
            src={theme === 'light-mode' ? AstreTableALight : AstreTableADark}
            alt="1er schéma des astres" title="1er schéma des astres"
          />
          <div className="info-part">
            <h4>Couple d'astres</h4>
            <p>
              On peut considérer les significations selon les modalités des valeurs solaires, leur importance
              dépendant de la puissance du Soleil. Ces planètes, astre errants, sont des « véhicules » qui
              tracent des signes de piste sur la route de la Vie, le Zodiaque.
            </p>
          </div>
        </InnerLayout>
        {/* Schéma des astres End Here */}
        {/* Structure binaire */}
        <InnerLayout className="astro-section-sans-image">
          <div className="info-part-sans-image">
            <p>
              Nous allons envisager les relations de « couples » des astres suivant leurs valeurs&nbsp;
              <span className="span-color">masculine</span> et <span className="span-color">féminines</span>.
            </p>
            <br/>
            <p>
              <table className="tableau-dirune-nocture-table" border={1}>
                <thead>
                  <tr className="table-border">
                    <th className="table-border td-options-bg">
                      <div className="text-center-title">Diurne</div>
                    </th>
                    <th className="table-border td-options-bg">
                    </th>
                    <th className="table-border td-options-bg">
                      <div className="text-center-title">Nocturne</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="table-border">
                    <td className="table-border td-options">
                      <div className="td-margin">
                        <div>Soi {ASTRE(astres, SOLEIL)}</div>
                      </div>
                    </td>
                    <td className="table-border td-options">
                      <div className="td-margin">
                        <div className="text-center-title">Soleil - Lune</div>
                      </div>
                    </td>
                    <td className="table-border td-options">
                      <div className="td-margin">
                        <div>Moi {ASTRE(astres, LUNE)}</div>
                      </div>
                    </td>
                  </tr>
                  <tr className="table-border">
                    <td className="table-border td-options">
                      <div className="td-margin">
                        <div>Lutte {ASTRE(astres, MARS)}</div>
                      </div>
                    </td>
                    <td className="table-border td-options">
                      <div className="td-margin">
                        <div className="text-center-title">Mars - Venus</div>
                      </div>
                    </td>
                    <td className="table-border td-options">
                      <div className="td-margin">
                        <div>Amour {ASTRE(astres, VENUS)}</div>
                      </div>
                    </td>
                  </tr>
                  <tr className="table-border">
                    <td className="table-border td-options">
                      <div className="td-margin">
                        <div>Intuition {ASTRE(astres, MERCURE)}</div>
                      </div>
                    </td>
                    <td className="table-border td-options">
                      <div className="td-margin">
                        <div className="text-center-title">Mercure - Ceres</div>
                      </div>
                    </td>
                    <td className="table-border td-options">
                      <div className="td-margin">
                        <div>Analyse {ASTRE(astres, CERES)}</div>
                      </div>
                    </td>
                  </tr>
                  <tr className="table-border">
                    <td className="table-border td-options">
                      <div className="td-margin">
                        <div>Expansion {ASTRE(astres, JUPITER)}</div>
                      </div>
                    </td>
                    <td className="table-border td-options">
                      <div className="td-margin">
                        <div className="text-center-title">Jupiter - Saturne</div>
                      </div>
                    </td>
                    <td className="table-border td-options">
                      <div className="td-margin">
                        <div>Rétraction {ASTRE(astres, SATURNE)}</div>
                      </div>
                    </td>
                  </tr>
                  <tr className="table-border">
                    <td className="table-border td-options">
                      <div className="td-margin">
                        <div>Révolution {ASTRE(astres, URANUS)}</div>
                      </div>
                    </td>
                    <td className="table-border td-options">
                      <div className="td-margin">
                        <div className="text-center-title">Uranus - Neptune</div>
                      </div>
                    </td>
                    <td className="table-border td-options">
                      <div className="td-margin">
                        <div>Contemplation {ASTRE(astres, NEPTUNE)}</div>
                      </div>
                    </td>
                  </tr>
                  <tr className="table-border">
                    <td className="table-border td-options">
                      <div className="td-margin">
                        <div>Mutation</div>
                      </div>
                    </td>
                    <td className="table-border td-options">
                      <div className="td-margin">
                        <div className="text-center-title">? - Pluton</div>
                      </div>
                    </td>
                    <td className="table-border td-options">
                      <div className="td-margin">
                        <div>Destruction {ASTRE(astres, PLUTON)}</div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </p>
            <br/>
            <h4>Soleil - Lune</h4>
            <p>
              Les luminaires, le <span className="span-color">Soleil</span> et la&nbsp;
              <span className="span-color">Lune</span> représentent des mondes en eux-même alors que les
              valeurs des autres astres sont dépendants justement du&nbsp;
              <span className="span-color">Soleil</span> et de la <span className="span-color">Lune</span>.
              <br/>
              <br/>
              Le <span className="span-color">Soleil</span> par exemple est le chef d'orchestre du Ciel et le
              transmetteur des planètes.
              <br/>
              <br/>
              Selon la tradition Babylonienne la naissance de l'<span className="span-color">Homme</span> a eu
              lieu pendant une <span className="span-color">Nouvelle Lune</span>, c'est à dire une conjonction
              du <span className="span-color">Soleil</span> et de la <span className="span-color">Lune</span>.
              <br/>
              <br/>
              Il est dit que <span className="span-color">Josua</span> arrêta le&nbsp;
              <span className="span-color">Soleil</span> qui préside aux saisons et que&nbsp;
              <span className="span-color">Mohammed</span> fendit la&nbsp;
              <span className="span-color">Lune</span> qui règle les marées. Les&nbsp;
              <span className="span-color">cultes solaires</span> sont parmi les plus anciens et communs à de
              nombreuses religions <span className="span-color">Surya</span> en Inde,&nbsp;
              <span className="span-color">Ra</span> en Egypte ou <span className="span-color">Apollon</span>
              &nbsp;en Grèce... les cultes solaires étaient initiatiques.
              <br/>
              <br/>
              La <span className="span-color">Lune</span> correspond a une régénération périodique en rapport
              direct avec la montée de la sève. Cette fécondité dans la végétation se retrouve aussi dans la
              fécondité chez la femme. Un psychiatre tchèque, E. Jonas, a prouvé que 87% des femmes sont
              fécondes lorsque le <span className="span-color">Soleil</span> et la&nbsp;
              <span className="span-color">Lune</span> se retrouve aux même positions zodicales que lors de la
              naissance. Quant au sexe de l'enfant il serait déterminé par la position de la la&nbsp;
              <span className="span-color">Lune</span> pendant la conception: en face d'un&nbsp;
              <span className="span-color">Signe « masculin »</span>, l'enfant serait mâle, en face d'un
              Signe <span className="span-color">Signe « feminin »</span> il serrait femelle.
              <br/>
              <br/>
              La <span className="span-color">Lune</span> agissant sur les humeurs (d'où l'expression
              « lunatique ») on connaît les recrudescences de crises des aliénées pendant la&nbsp;
              <span className="span-color">Pleine Lune</span> (opposition du&nbsp;
              <span className="span-color">Soleil</span> et de la <span className="span-color">Lune</span>).
              Cette action sur les troubles mentaux, accentuant des déséquilibres, peut d'ailleurs favoriser
              une certaine « voyance » de type sensoriel.
              <br/>
              <br/>
              Ainsi la <span className="span-color">Lune</span> qui règle les marées comme les règles des
              femmes est en coïncidence avec la nature. Même le changement de peaux des serpent se produit
              selon un rythme lunaire.
              <br/>
              <br/>
              Sur un plan psychique la sécurisation maternelle est en analogie avec la&nbsp;
              <span className="span-color">Lune</span> qui envloppe la terre comme la mère enveloppe son
              enfant. Cet amour maternel se développant bien entendu dans le mariage (
              <span className="span-color">Venus</span> correspondrait alors plutôt à l'union libre), il
              s'agira d'observer la position de la <span className="span-color">Lune</span> dans le thème d'un
              enfant pour connaître la mère.
              <br/>
              <br/>
              La <span className="span-color">volonté « solaire »</span> se réfère à l'affirmation du
              rayonnement, brillance du commandement, et quand le <span className="span-color">Soleil</span>
              « descend » la nuit au royaume des morts, il est psychopompe, il emmène des âmes puis les fait
              ressortir, avec le jour à la lumière.
              <br/>
              <br/>
              Le rapport <span className="span-color">Soleil - Lune</span> signifie aussi la création à la
              procréation comme l'instant à la duréee. De l'impersonnel
              (<span className="span-color">Soleil</span>) au personnel
              (<span className="span-color">Lune</span>) on passe de l'abstrait au concret c'est à dire sur un
              plan maintenant métaphysique du <span className="span-color">Soi</span> au&nbsp;
              <span className="span-color">Moi</span>, de la <span className="span-color">Gnose</span> à
              la <span className="span-color">Mystique</span>.
            </p>
            <br/>
            <h4>Mars - Venus</h4>
            <p>
              L'activité martienne portée à la violence est compensée par la douceur vénusienne. Elle reçoit
              ce qu'il donne et repose le guerrier... Ce rapport entre l'action et la sensation se vérifie
              dans la vie courante entre, par exemple, le sportif et l'artiste.
              <br/>
              <br/>
              A la limite on assiste à la confrontation des élans de haine et d'amour qui ne peuvent
              s'entendre que dans une complémentarité librement acceptée. la force de&nbsp;
              <span className="span-color">Mars</span> réside dans son héroïsme, ses erreurs dans son
              agressivité. Mais il peut tomber dans les charmes de la séduction de&nbsp;
              <span className="span-color">Venus</span> qui sait allier la tendresse à la passion.
            </p>
            <br/>
            <h4>Mercure - Cerese</h4>
            <p>
              <span className="span-color">Mercure</span>, « messager de dieux » mais aussi patron des
              marchands et des voleurs, agit dans la relation proprement dite. Bien que ses valeurs
              d'androgyne le situe au-delà des contraires&nbsp;
              <span className="span-color">« masculin - féminin »</span>, il est possible de l'unir à Cérés.
              <br/>
              <br/>
              Elle est « la croissance personnifiée » et possède toutes les caractéristiques d'un&nbsp;
              <span className="span-color">« Saturne féminisé »</span>. Le premier des astéorïde circulant
              entre <span className="span-color">Mars</span> et <span className="span-color">Jupiter</span>
              &nbsp;n'est pas utilisé par tous les astrologues, peu s'en faut.
            </p>
            <br/>
            <h4>Jupiter - Saturne</h4>
            <p>
              Si <span className="span-color">Jupiter</span>, dieu des dieux, est conscient de sa dignité, de
              sa noblesse, de sa bonté, par contre <span className="span-color">Saturne</span>, dieu du temps,
              dans sont isolement, son égotisme, montre une prudence tournant à la méfiance mais une
              profondeur souvent remarquable face à Jupiter qui fait le bon roi ou l'homme d'affaire bon
              vivant.
              <br/>
              <br/>
              <span className="span-color">Saturne</span> représente, dans son durcissement, une volonté de
              contraction allant jusqu'à la frustration, opposé à la volonté d'expansion de&nbsp;
              <span className="span-color">Jupiter</span> qui prend ce que rejette&nbsp;
              <span className="span-color">Saturne</span>.
              <br/>
              <br/>
              Cette solidité saturnienne s'opposnant à toute futilité va de pères du désert aux avares
              mesquins et c'est toujours le silence et la solitude qui sont vécus selon cette force sèche.
              Tandis que <span className="span-color">Jupiter</span> s'épanouit, jovial, et développe ses
              qualités de séduction non exempte d'exhibitionnisme et d'autorité. Face à lui, Saturne se
              renferme en lui-même.
              <br/>
              <br/>
              Signalons que les <span className="span-color">Sabéens</span> voyaient de manière
              dualiste <span className="span-color">Saturne</span> comme le&nbsp;
              <span className="span-color">« malheur »</span> et&nbsp;
              <span className="span-color">Jupiter</span> comme le&nbsp;
              <span className="span-color">« bonheur »</span>. Les caractères maléfique de l'un et bénéfique
              de l'autre sont restés, mais en nuançant leurs significations.
            </p>
            <br/>
            <h4>Uranus - Neptune</h4>
            <p>
              <span className="span-color">Uranus</span> est à l'individuel ce qu'est&nbsp;
              <span className="span-color">Neptune</span> au collectif. La volonté de changement du révolté
              uraniein apparaît nettement et plus clairement mais avec moins de subtilité que les élans&nbsp;
              <span className="span-color">« mystiques »</span> de <span className="span-color">Neptune</span>
              &nbsp;généralement plutôt <span className="span-color">« vagues »</span> et indistincts.
              <br/>
              <br/>
              <span className="span-color">Uranus</span> tient de <span className="span-color">Mars</span>
              et <span className="span-color">Neptune</span> tien de la&nbsp;
              <span className="span-color">Lune</span>...
              <br/>
              <br/>
              A partir d'<span className="span-color">Uranus</span>, planète découverte à la Révolution, les
              valeurs que ces astres représentent sont moins évidentes.
              <br/>
              <br/>
              D'<span className="span-color">Uranus</span> à <span className="span-color">Neptune</span> on va
              de la <span className="span-color">Forme</span> au&nbsp;
              <span className="span-color">Sans-forme</span>.
            </p>
            <br/>
            <h4>? - Pluton</h4>
            <p>
              Tout porte à croire qu'il existe un astre (ou des astéroïdes) au-delà de&nbsp;
              <span className="span-color">Pluton</span>. Les arabes l'appellent: « Kaïd », les occidentaux
              le nommeront sans doute « Proserpine ».
              <br/>
              <br/>
              Nous entrons dans le domaine des transmutations et des « daïmons ».&nbsp;
              <span className="span-color">Pluton</span> est le maître des courants telluriques et l'astre
              transplutonien, « diable de la sphère céleste » selon les Iraniens, il serait le maître des
              courants cosmiques. Dialectique entre les forces du subconscient, de la&nbsp;
              <span className="span-color">Matière</span> dominant l'&nbsp;
              <span className="span-color">Esprit</span>, et les forces du surconscient, de l'&nbsp;
              <span className="span-color">Esprit</span> dominant la&nbsp;
              <span className="span-color">Matière</span>.
              <br/>
              <br/>
              Puissance lumineuses mais noires, à la limite de notre système solaire veillent deux diables,
              gardiens de la ronde de la <span className="span-color">Vie</span>, marquant le passage infernal
              des portes célestes...
              <br/>
              <br/>
              L'<span className="span-color">Homme</span> est le canal du subhumain au surhumain. Son&nbsp;
              <span className="span-color">« âme »</span> est bien une « étincelle de substances » d'astres
              comme le signalait <span className="span-color">Pythagore</span>, et il doit s'élever à la
              nature de l'astre qui lui correspond.
              <br/>
              <br/>
              Pour <span className="span-color">Platon</span> le mouvement des astres était: « l'image mobile
              de l'éternité immobile »
            </p>
          </div>
        </InnerLayout>
        {/* Structure binaire Here */}
      </AstroLayout>
    </LesAstresStyled>
  )
}

const LesAstresStyled = styled.div`
  .tableau-dirune-nocture-table {
    width: 100%;
    border: 2px solid var(--primary-color);
    border-collapse: collapse;

    div {
      font-size: .9rem;
    }
  }

  .table-border {
    border: 2px solid var(--primary-color);
  }

  .td-options {
    vertical-align: center;
  }

  .td-options-bg {
    vertical-align: center;
    background: var(--sidebar-color);
  }

  .td-margin {
    margin-left: 1rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .text-center {
    text-align: center !important;
  }

  .text-center-title {
    text-align: center !important;
    color: var(--primary-color) !important;
  }
`

export default LesAstresPage
