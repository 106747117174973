import React from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import styled from 'styled-components'
import 'react-toastify/dist/ReactToastify.css'
import {
  LA_MAISON_I,
  LA_MAISON_II,
  LA_MAISON_III,
  LA_MAISON_IV,
  LA_MAISON_V,
  LA_MAISON_VI,
  LA_MAISON_VII,
  LA_MAISON_VIII,
  LA_MAISON_IX,
  LA_MAISON_X,
  LA_MAISON_XI,
  LA_MAISON_XII,
  LES_MAISONS,
  LES_MAISONS_RAPPORT_I_ET_VII,
  LES_MAISONS_RAPPORT_II_ET_VIII,
  LES_MAISONS_RAPPORT_III_ET_IX,
  LES_MAISONS_RAPPORT_IV_ET_X,
  LES_MAISONS_RAPPORT_V_ET_XI,
  LES_MAISONS_RAPPORT_VI_ET_XII
} from '../data/Liens'
import { InnerLayout } from '../styles/Layouts'
import { AstroLayout } from '../styles/AstroLayout'

function LesMaisonsSchemaBinaireOppositions(): JSX.Element {
  return (
    <LesMaisonsSchemaBinaireOppositionsStyled>
      <AstroLayout>
        {/* Tableau Schéma binaire */}
        <InnerLayout className="astro-section-sans-image">
          <div className="info-part-sans-image">
            <h4>Schéma binaire avec les oppositions diurne/nocturne à 180°</h4>
            <p>
              <table className="structure-binaire-table" border={1}>
                <tr className="table-border">
                  <th className="table-border td-options-top th-1">
                    <div className="td-margin">
                      <div className="text-center-title">Maisons<br/>diurne</div>
                      <br/>
                      <div className="text-center">Extériorisation</div>
                    </div>
                  </th>
                  <td className="table-border td-options th-2">
                    <div className="td-margin">
                      <div className="text-center">relation<br />mariage<br/>conjoint<br />collaboration<br />contrats<br />engagement<br />procès<br/>conflits</div>
                      <br/>
                      <div className="text-center-title">ASSOCIATIONS</div>
                      <br/>
                      <div className="text-center">« L’époux »</div>
                      <br/>
                      <div className="text-center-title">
                        <Link to={LA_MAISON_VII + '#DEFINITION'}>Maison VII</Link>
                      </div>
                    </div>
                  </td>
                  <td className="table-border td-options th-2">
                    <div className="td-margin">
                      <div className="text-center">sexualité<br />désir<br />héritages<br/>legs<br />manques<br />chagrin<br />occultisme<br />magie</div>
                      <br/>
                      <div className="text-center-title">TRANSFORMATIONS</div>
                      <br/>
                      <div className="text-center">« La mort »</div>
                      <br/>
                      <div className="text-center-title">
                        <Link to={LA_MAISON_VIII + '#DEFINITION'}>Maison VIII</Link>
                      </div>
                    </div>
                  </td>
                  <td className="table-border td-options th-2">
                    <div className="td-margin">
                      <div className="text-center">philosophie<br/>voyages lointaints<br />études supérieurs<br />sciences occulte<br />élévations spirituelles<br />idéal<br />fois<br />sacerdoce</div>
                      <br/>
                      <div className="text-center-title">VOYAGES</div>
                      <br/>
                      <div className="text-center">« L'exploration »</div>
                      <br/>
                      <div className="text-center-title">
                        <Link to={LA_MAISON_IX + '#DEFINITION'}>Maison IX</Link>
                      </div>
                    </div>
                  </td>
                  <td className="table-border td-options th-2">
                    <div className="td-margin">
                      <div className="text-center">le règne<br/>pouvoir<br />position sociale<br />honneurs<br />reconnaissance<br/>dignité<br />la mère pour natif masculine<br />le père pour natif féminin</div>
                      <br/>
                      <div className="text-center-title">DESTINÉ</div>
                      <br/>
                      <div className="text-center">« La carrière »</div>
                      <br/>
                      <div className="text-center-title">
                        <Link to={LA_MAISON_X + '#DEFINITION'}>Maison X</Link>
                      </div>
                    </div>
                  </td>
                  <td className="table-border td-options th-2">
                    <div className="td-margin">
                      <div className="text-center">projets<br/>collectivité<br />appuis<br />relations<br />groupes<br />affinités<br />protections<br />espérances</div>
                      <br/>
                      <div className="text-center-title">SOUTIENS</div>
                      <br/>
                      <div className="text-center">« Les amis »</div>
                      <br/>
                      <div className="text-center-title">
                        <Link to={LA_MAISON_XI + '#DEFINITION'}>Maison XI</Link>
                      </div>
                    </div>
                  </td>
                  <td className="table-border td-options th-2">
                    <div className="td-margin">
                      <div className="text-center">les ennemis privés<br/>l'exil<br />une longue maladie<br />crime<br />grand animaux<br />l'inconscient<br />le sommeil<br />la vie occulte</div>
                      <br/>
                      <div className="text-center-title">ÉPREUVE</div>
                      <br/>
                      <div className="text-center">« Difficulté secrète de l’être »</div>
                      <br/>
                      <div className="text-center-title">
                        <Link to={LA_MAISON_XII + '#DEFINITION'}>Maison XII</Link>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="table-border">
                  <th className="table-border td-middle">
                    <div className="td-margin">
                      <div className="text-center-title">
                        <Link to={LES_MAISONS + '#LES_DOUZE_MAISONS'}>Les douze Maisons</Link>
                      </div>
                    </div>
                  </th>
                  <td className="table-border td-middle">
                    <div className="td-margin">
                      <Link to={LES_MAISONS_RAPPORT_I_ET_VII + '#RAPPORT'}>
                        <div className="text-center-title">Rapport I et VII</div>
                      </Link>
                    </div>
                  </td>
                  <td className="table-border td-middle">
                    <div className="td-margin">
                      <Link to={LES_MAISONS_RAPPORT_II_ET_VIII + '#RAPPORT'}>
                        <div className="text-center-title">Rapport II et VIII</div>
                      </Link>
                    </div>
                  </td>
                  <td className="table-border td-middle">
                    <div className="td-margin">
                      <Link to={LES_MAISONS_RAPPORT_III_ET_IX + '#RAPPORT'}>
                        <div className="text-center-title">Rapport III et IX</div>
                      </Link>
                    </div>
                  </td>
                  <td className="table-border td-middle">
                    <div className="td-margin">
                      <Link to={LES_MAISONS_RAPPORT_IV_ET_X + '#RAPPORT'}>
                        <div className="text-center-title">Rapport IV et X</div>
                      </Link>
                    </div>
                  </td>
                  <td className="table-border td-middle">
                    <div className="td-margin">
                      <Link to={LES_MAISONS_RAPPORT_V_ET_XI + '#RAPPORT'}>
                        <div className="text-center-title">Rapport V et XI</div>
                      </Link>
                    </div>
                  </td>
                  <td className="table-border td-middle">
                    <div className="td-margin">
                      <Link to={LES_MAISONS_RAPPORT_VI_ET_XII + '#RAPPORT'}>
                        <div className="text-center-title">Rapport VI et XII</div>
                      </Link>
                    </div>
                  </td>
                </tr>
                <tr className="table-border">
                  <th className="table-border td-options-bottom">
                    <div className="td-margin">
                      <div className="text-center">Intériorisation</div>
                      <br/>
                      <div className="text-center-title">Maisons<br/>nocturne</div>
                    </div>
                  </th>
                  <td className="table-border td-options">
                    <div className="td-margin">
                      <div className="text-center-title">
                        <Link to={LA_MAISON_I + '#DEFINITION'}>Maison I</Link>
                      </div>
                      <br/>
                      <div className="text-center">« La vie, l'instant présent »</div>
                      <br/>
                      <div className="text-center-title">IDENTITÉ</div>
                      <br/>
                      <div className="text-center">comportement<br/>apparence extérieure<br />intimité<br />affirmation de soi<br />naturel<br />initiative solitaire<br />spontanéité<br />autonomie<br /></div>
                    </div>
                  </td>
                  <td className="table-border td-options">
                    <div className="td-margin">
                      <div className="text-center-title">
                        <Link to={LA_MAISON_II + '#DEFINITION'}>Maison II</Link>
                      </div>
                      <br/>
                      <div className="text-center">« La gain »</div>
                      <br/>
                      <div className="text-center-title">BIENS</div>
                      <br/>
                      <div className="text-center">richesse<br/>pauvreté<br />effort personnel<br />économie<br />finance<br />possessions<br />ressources<br />transactions</div>
                    </div>
                  </td>
                  <td className="table-border td-options">
                    <div className="td-margin">
                      <div className="text-center-title">
                        <Link to={LA_MAISON_III + '#DEFINITION'}>Maison III</Link>
                      </div>
                      <br/>
                      <div className="text-center">« Les frères et sœurs »</div>
                      <br/>
                      <div className="text-center-title">RELATIONS</div>
                      <br/>
                      <div className="text-center">études<br/>intelligence<br />communications<br />créations intellectuelles<br />entourage proche<br />voisins<br />familiers<br/>petits voyages</div>
                    </div>
                  </td>
                  <td className="table-border td-options">
                    <div className="td-margin">
                      <div className="text-center-title">
                        <Link to={LA_MAISON_IV + '#DEFINITION'}>Maison IV</Link>
                      </div>
                      <br/>
                      <div className="text-center">« Les parents »</div>
                      <br/>
                      <div className="text-center-title">ORIGINE</div>
                      <br/>
                      <div className="text-center">foyer<br/>biens immobiliers<br />passé<br />le père pour natif masculin<br />la mère pour natif féminin<br />liens familiaux<br />le lieu de naissance<br />la fin de vie</div>
                    </div>
                  </td>
                  <td className="table-border td-options">
                    <div className="td-margin">
                      <div className="text-center-title">
                        <Link to={LA_MAISON_V + '#DEFINITION'}>Maison V</Link>
                      </div>
                      <br/>
                      <div className="text-center">« L'art et les enfants »</div>
                      <br/>
                      <div className="text-center-title">CRÉATIONS</div>
                      <br/>
                      <div className="text-center">plaisirs de la vie<br />sentiments<br/>fête<br />loisirs<br />vacances<br />divertissements<br />jeux de hasard<br />sports</div>
                    </div>
                  </td>
                  <td className="table-border td-options">
                    <div className="td-margin">
                      <div className="text-center-title">
                        <Link to={LA_MAISON_VI + '#DEFINITION'}>Maison VI</Link>
                      </div>
                      <br/>
                      <div className="text-center">« La santé »</div>
                      <br/>
                      <div className="text-center-title">CORVÉES</div>
                      <br/>
                      <div className="text-center">travail<br/>responsabilité<br />servitudes<br/>hygiène<br />alimentation<br/>alimentation<br/>obligations<br />routine<br />animaux domestique</div>
                    </div>
                  </td>
                </tr>
              </table>
            </p>
          </div>
        </InnerLayout>
      </AstroLayout>
    </LesMaisonsSchemaBinaireOppositionsStyled>
  )
}

const LesMaisonsSchemaBinaireOppositionsStyled = styled.div`
  .structure-binaire-table {
    width: 100%;
    border: 2px solid var(--primary-color);
    border-collapse: collapse;

    div {
      font-size: .7rem;
    }
  }

  .table-border {
    border: 2px solid var(--primary-color);
  }

  .td-options {
    vertical-align: center;
  }

  .td-options-bottom {
    vertical-align: bottom;
    background: var(--sidebar-color);
  }

  .td-options-top {
    vertical-align: top;
    background: var(--sidebar-color);
  }

  .td-middle {
    background: var(--sidebar-color);
  }

  .td-margin {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .text-center {
    text-align: center !important;
  }

  .text-center-title {
    text-align: center !important;
    color: var(--primary-color) !important;
  }
  
`

export default LesMaisonsSchemaBinaireOppositions
