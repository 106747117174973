import React from 'react'
import styled from 'styled-components'
import 'react-toastify/dist/ReactToastify.css'
import { InnerLayout } from '../styles/Layouts'
import { AstroLayout } from '../styles/AstroLayout'
import { HashLink as Link } from 'react-router-hash-link'
import {
  LA_MAISON_I,
  LA_MAISON_II,
  LA_MAISON_III,
  LA_MAISON_IV,
  LA_MAISON_V,
  LA_MAISON_VI,
  LA_MAISON_VII,
  LA_MAISON_VIII,
  LA_MAISON_IX,
  LA_MAISON_X,
  LA_MAISON_XI,
  LA_MAISON_XII
} from '../data/Liens'
import ZodiacSphereLocaleLight from '../images/Svg/zodiac-sphere-locale-light.svg'
import ZodiacSphereLocaleDark from '../images/Svg/zodiac-sphere-locale-dark.svg'
import MAISON_I_SVG from '../images/Svg/LesMaisons/MAISON_I.svg'
import MAISON_II_SVG from '../images/Svg/LesMaisons/MAISON_II.svg'
import MAISON_III_SVG from '../images/Svg/LesMaisons/MAISON_III.svg'
import MAISON_IV_SVG from '../images/Svg/LesMaisons/MAISON_IV.svg'
import MAISON_V_SVG from '../images/Svg/LesMaisons/MAISON_V.svg'
import MAISON_VI_SVG from '../images/Svg/LesMaisons/MAISON_VI.svg'
import MAISON_VII_SVG from '../images/Svg/LesMaisons/MAISON_VII.svg'
import MAISON_VIII_SVG from '../images/Svg/LesMaisons/MAISON_VIII.svg'
import MAISON_IX_SVG from '../images/Svg/LesMaisons/MAISON_IX.svg'
import MAISON_X_SVG from '../images/Svg/LesMaisons/MAISON_X.svg'
import MAISON_XI_SVG from '../images/Svg/LesMaisons/MAISON_XI.svg'
import MAISON_XII_SVG from '../images/Svg/LesMaisons/MAISON_XII.svg'

type ZodiacSphereLocaleProps = {
  theme: string
}

function ZodiacSphereLocale({ theme }: ZodiacSphereLocaleProps): JSX.Element {
  return (
    <ZodiacSphereLocaleStyled>
      <InnerLayout>
        <AstroLayout className="image-relative">
          <img
            src={theme === 'light-mode' ? ZodiacSphereLocaleLight : ZodiacSphereLocaleDark}
            alt="Structure de la sphère locale"
          />
          <div className="image-absolute">
            <Link
              to={LA_MAISON_I + '#DEFINITION'}
              className={'overlay-link'}
              rel="noopener noreferrer"
              title="Maison I"
              style={{
                top: '48%',
                left: '3%',
                backgroundImage: `url(${MAISON_I_SVG})`
              }}
            >
            </Link>
            <Link
              to={LA_MAISON_II + '#DEFINITION'}
              className={'overlay-link'}
              rel="noopener noreferrer"
              title="Maison II"
              style={{
                top: '59%',
                left: '7%',
                backgroundImage: `url(${MAISON_II_SVG})`
              }}
            >
            </Link>
            <Link
              to={LA_MAISON_III + '#DEFINITION'}
              className={'overlay-link'}
              rel="noopener noreferrer"
              title="Maison III"
              style={{
                top: '68%',
                left: '18%',
                backgroundImage: `url(${MAISON_III_SVG})`
              }}
            >
            </Link>
            <Link
              to={LA_MAISON_IV + '#DEFINITION'}
              className={'overlay-link'}
              rel="noopener noreferrer"
              title="Maison IV"
              style={{
                top: '68%',
                left: '40%',
                backgroundImage: `url(${MAISON_IV_SVG})`
              }}
            >
            </Link>
            <Link
              to={LA_MAISON_V + '#DEFINITION'}
              className={'overlay-link'}
              rel="noopener noreferrer"
              title="Maison V"
              style={{
                top: '62%',
                left: '60%',
                backgroundImage: `url(${MAISON_V_SVG})`
              }}
            >
            </Link>
            <Link
              to={LA_MAISON_VI + '#DEFINITION'}
              className={'overlay-link'}
              rel="noopener noreferrer"
              title="Maison VI"
              style={{
                top: '48%',
                left: '67%',
                backgroundImage: `url(${MAISON_VI_SVG})`
              }}
            >
            </Link>
            <Link
              to={LA_MAISON_VII + '#DEFINITION'}
              className={'overlay-link'}
              rel="noopener noreferrer"
              title="Maison VII"
              style={{
                top: '35%',
                left: '67%',
                backgroundImage: `url(${MAISON_VII_SVG})`
              }}
            >
            </Link>
            <Link
              to={LA_MAISON_VIII + '#DEFINITION'}
              className={'overlay-link'}
              rel="noopener noreferrer"
              title="Maison VIII"
              style={{
                top: '21%',
                left: '62%',
                backgroundImage: `url(${MAISON_VIII_SVG})`
              }}
            >
            </Link>
            <Link
              to={LA_MAISON_IX + '#DEFINITION'}
              className={'overlay-link'}
              rel="noopener noreferrer"
              title="Maison IX"
              style={{
                top: '12%',
                left: '50%',
                backgroundImage: `url(${MAISON_IX_SVG})`
              }}
            >
            </Link>
            <Link
              to={LA_MAISON_X + '#DEFINITION'}
              className={'overlay-link'}
              rel="noopener noreferrer"
              title="Maison X"
              style={{
                top: '10%',
                left: '31%',
                backgroundImage: `url(${MAISON_X_SVG})`
              }}
            >
            </Link>
            <Link
              to={LA_MAISON_XI + '#DEFINITION'}
              className={'overlay-link'}
              rel="noopener noreferrer"
              title="Maison IX"
              style={{
                top: '17%',
                left: '12%',
                backgroundImage: `url(${MAISON_XI_SVG})`
              }}
            >
            </Link>
            <Link
              to={LA_MAISON_XII + '#DEFINITION'}
              className={'overlay-link'}
              rel="noopener noreferrer"
              title="Maison XII"
              style={{
                top: '32%',
                left: '4%',
                backgroundImage: `url(${MAISON_XII_SVG})`
              }}
            >
            </Link>
          </div>
        </AstroLayout>
      </InnerLayout>
    </ZodiacSphereLocaleStyled>
  )
}

const ZodiacSphereLocaleStyled = styled.div`
  .image-relative {
    position: relative;
    width: 600px;
    height: 600px;
  }
  .overlay-link {
    display: block;
    position: absolute;
    width: 80px;
    height: 30px;
    background-size: cover;
    text-decoration: none;
  }
  
`

export default ZodiacSphereLocale
