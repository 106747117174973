import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { InnerLayout } from '../styles/Layouts'
import { AstroLayout } from '../styles/AstroLayout'
import Title from '../components/Title'
import ZodiacDominanteADark from '../images/Svg/zodiac-dominante-a-dark.svg'
import ZodiacDominanteALight from '../images/Svg/zodiac-dominante-a-light.svg'
import ZodiacDominanteBDark from '../images/Svg/zodiac-dominante-b-dark.svg'
import ZodiacDominanteBLight from '../images/Svg/zodiac-dominante-b-light.svg'
import ZodiacDominanteCDark from '../images/Svg/zodiac-dominante-c-dark.svg'
import ZodiacDominanteCLight from '../images/Svg/zodiac-dominante-c-light.svg'
import ZodiacDominanteDDark from '../images/Svg/zodiac-dominante-d-dark.svg'
import ZodiacDominanteDLight from '../images/Svg/zodiac-dominante-d-light.svg'
import ZodiacDominanteEDark from '../images/Svg/zodiac-dominante-e-dark.svg'
import ZodiacDominanteELight from '../images/Svg/zodiac-dominante-e-light.svg'
import Loading from '../components/Loading'
import DbTexte from '../components/DbTexte'
import { RECHERCHE_DOMINANTE } from '../data/Liens'

type RechercheDominantePageProps = {
  theme: string
}

function RechercheDominantePage({ theme }: RechercheDominantePageProps): JSX.Element {
  const [swLoaded, setSwLoaded] = useState(false)

  useEffect(() => {
    setTimeout(function() {
      setSwLoaded(true)
    }, 100)
  }, [])

  return (
    !swLoaded ? <Loading /> : <RechercheDominanteStyled>
      <AstroLayout>
        {/* about Start Here */}
        <Title title={'Recherche'} span={'de la dominante'}/>
        {/* About End Here */}

        {/* Les divers types astraux */}
        <InnerLayout className="astro-section-sans-image">
          <div className="info-part-sans-image">
            <DbTexte
              titreCall="LES_DIVERS_TYPE_DE_THEMES_ASTRAUX"
              lien={RECHERCHE_DOMINANTE}
              theme={theme}
            />
          </div>
        </InnerLayout>
        {/* Les divers types astraux End Here */}
        {/* a) Le thème "éclaté" */}
        <InnerLayout className="astro-section">
          <img
            className="image-part"
            src={theme === 'light-mode' ? ZodiacDominanteALight : ZodiacDominanteADark}
            alt="Structure de la sphère locale"
          />
          <div className="info-part">
            <DbTexte
              titreCall="LE_THEME_ECLATE"
              lien={RECHERCHE_DOMINANTE}
              theme={theme}
            />
          </div>
        </InnerLayout>
        {/* a) Le thème "éclaté" End Here */}
        {/* b) L'angle d'orient */}
        <InnerLayout className="astro-section">
          <img
            className="image-part"
            src={theme === 'light-mode' ? ZodiacDominanteBLight : ZodiacDominanteBDark}
            alt="Structure de la sphère locale"
          />
          <div className="info-part">
            <DbTexte
              titreCall="L_ANGLE_D_ORIENT"
              lien={RECHERCHE_DOMINANTE}
              theme={theme}
            />
          </div>
        </InnerLayout>
        {/* b) L'angle d'orient End Here */}
        {/* c) L'angle d'occident */}
        <InnerLayout className="astro-section">
          <img
            className="image-part"
            src={theme === 'light-mode' ? ZodiacDominanteCLight : ZodiacDominanteCDark}
            alt="Structure de la sphère locale"
          />
          <div className="info-part">
            <DbTexte
              titreCall="L_ANGLE_D_OCCIDENT"
              lien={RECHERCHE_DOMINANTE}
              theme={theme}
            />
          </div>
        </InnerLayout>
        {/* c) L'angle d'occident End Here */}
        {/* d) L'angle de jour */}
        <InnerLayout className="astro-section">
          <img
            className="image-part"
            src={theme === 'light-mode' ? ZodiacDominanteDLight : ZodiacDominanteDDark}
            alt="Structure de la sphère locale"
          />
          <div className="info-part">
            <DbTexte
              titreCall="L_ANGLE_DE_JOUR"
              lien={RECHERCHE_DOMINANTE}
              theme={theme}
            />
          </div>
        </InnerLayout>
        {/* d) l'angle de jour End Here */}
        {/* e) L'angle de nuit */}
        <InnerLayout className="astro-section">
          <img
            className="image-part"
            src={theme === 'light-mode' ? ZodiacDominanteELight : ZodiacDominanteEDark}
            alt="Structure de la sphère locale"
          />
          <div className="info-part">
            <DbTexte
              titreCall="L_ANGLE_DE_NUIT"
              lien={RECHERCHE_DOMINANTE}
              theme={theme}
            />
          </div>
        </InnerLayout>
        {/* e) l'angle de nuit End Here */}
      </AstroLayout>
    </RechercheDominanteStyled>
  )
}

const RechercheDominanteStyled = styled.div`
  /* fade */
  -webkit-animation: my-fade 1s; /* Chrome, Safari, Opera */
  animation: my-fade 1s;
  .recherche-dominante-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: .8rem;
    @media screen and (max-width: 800px) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 1.5rem;
    }
  }

  .recherche-dominante-section-sans-image {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: .8rem;
    @media screen and (max-width: 800px) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 1.5rem;
    }
  }

  .image-part {
    height: 100%;
    width: 100%;

    img {
      width: 90%;
      height: 90%;
    }
  }

  @media screen and (min-width: 0px) and (max-width: 599px) {
    .image-part {
      height: 330px !important;
      width: 330px !important;
    }
  }

  @media screen and (min-width: 600px) and (max-width: 1023px) {
    .image-part {
      height: 430px !important;
      width: 430px !important;
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1599px) {
    .image-part {
      height: 500px !important;
      width: 500px !important;
    }
  }

  @media screen and (min-width: 1600px) {
    .image-part {
      height: 600px !important;
      width: 600px !important;
    }
  }

  .info-part-sans-image {
    h4 {
      color: var(--primary-color);
      font-size: 1.3rem;
    }

    h3 {
      margin: 1rem 0;
      font-size: 1.9rem;

      span {
        color: var(--primary-color);
      }
    }
  }

  .info-part {
    h4 {
      color: var(--primary-color);
      font-size: 1.3rem;
    }

    h3 {
      margin: 1rem 0;
      font-size: 1.9rem;

      span {
        color: var(--primary-color);
      }
    }

    p {
      font-size: .9rem;
    }

    .information {
      margin: .5rem 0;
      width: 90%;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 500px) {
        width: 100%;
      }

      ul {
        li {
          margin: .5rem 0;
          @media screen and (max-width: 500px) {
            margin: 1rem 0;
            font-size: .9rem;
          }
        }
      }

      .details {
        color: var(--paragraph-color);
      }
    }
  }
`

export default RechercheDominantePage
