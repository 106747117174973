// Const
const SOLEIL = 0
const LUNE = 1
const MERCURE = 2
const VENUS = 3
const MARS = 4
const JUPITER = 5
const SATURNE = 6
const URANUS = 7
const NEPTUNE = 8
const PLUTON = 9
const NOEUD_LUNAIRE = 10
const CERES = 17

type AstreProp = {
  nom: string
  svg: string
  // eslint-disable-next-line camelcase
  id_swe: number
}

// Functions
function ASTRE(a: AstreProp[], idSwe: number): JSX.Element {
  let astreJson = a.find(el => el.id_swe === idSwe)
  if (astreJson === undefined) {
    astreJson = { nom: '', svg: '', id_swe: idSwe }
  }
  return (
    <img
      className="icon-astre-svg"
      src={'data:image/svg+xml;base64,' + astreJson.svg}
      alt={astreJson.nom}
      title={astreJson.nom}
    />
  )
}

export { SOLEIL, LUNE, MERCURE, VENUS, MARS, JUPITER, SATURNE, URANUS, NEPTUNE, PLUTON, NOEUD_LUNAIRE, CERES, ASTRE }
