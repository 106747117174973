const LES_MAISONS = '/les-maisons'
const LES_MAISONS_RAPPORT_I_ET_VII = '/les-maisons-rapport-I-et-VII'
const LES_MAISONS_RAPPORT_II_ET_VIII = '/les-maisons-rapport-II-et-VIII'
const LES_MAISONS_RAPPORT_III_ET_IX = '/les-maisons-rapport-III-et-IX'
const LES_MAISONS_RAPPORT_IV_ET_X = '/les-maisons-rapport-IV-et-X'
const LES_MAISONS_RAPPORT_V_ET_XI = '/les-maisons-rapport-V-et-XI'
const LES_MAISONS_RAPPORT_VI_ET_XII = '/les-maisons-rapport-VI-et-XII'
const LA_MAISON_I = '/la-maison-I'
const LA_MAISON_II = '/la-maison-II'
const LA_MAISON_III = '/la-maison-III'
const LA_MAISON_IV = '/la-maison-IV'
const LA_MAISON_V = '/la-maison-V'
const LA_MAISON_VI = '/la-maison-VI'
const LA_MAISON_VII = '/la-maison-VII'
const LA_MAISON_VIII = '/la-maison-VIII'
const LA_MAISON_IX = '/la-maison-IX'
const LA_MAISON_X = '/la-maison-X'
const LA_MAISON_XI = '/la-maison-XI'
const LA_MAISON_XII = '/la-maison-XII'
const RECHERCHE_DOMINANTE = '/recherche-dominante'
export {
  LES_MAISONS,
  LES_MAISONS_RAPPORT_I_ET_VII,
  LES_MAISONS_RAPPORT_II_ET_VIII,
  LES_MAISONS_RAPPORT_III_ET_IX,
  LES_MAISONS_RAPPORT_IV_ET_X,
  LES_MAISONS_RAPPORT_V_ET_XI,
  LES_MAISONS_RAPPORT_VI_ET_XII,
  LA_MAISON_I,
  LA_MAISON_II,
  LA_MAISON_III,
  LA_MAISON_IV,
  LA_MAISON_V,
  LA_MAISON_VI,
  LA_MAISON_VII,
  LA_MAISON_VIII,
  LA_MAISON_IX,
  LA_MAISON_X,
  LA_MAISON_XI,
  LA_MAISON_XII,
  RECHERCHE_DOMINANTE
}
