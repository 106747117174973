import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Title from '../components/Title'
import { InnerLayout } from '../styles/Layouts'
import ZodiacSphereLocaleDark from '../images/Svg/zodiac-sphere-locale-dark.svg'
import ZodiacSphereLocaleLight from '../images/Svg/zodiac-sphere-locale-light.svg'
import Loading from '../components/Loading'
import DbTexte from '../components/DbTexte'
import { LES_MAISONS } from '../data/Liens'
import LesMaisonsSchemaBinaireOppositions from '../components/LesMaisonsSchemaBinaireOppositions'
import { AstroLayout } from '../styles/AstroLayout'
import ZodiacSphereLocale from '../components/ZodiacSphereLocale'
import ZodiacStructLight from '../images/Svg/zodiac-struct-light.svg'
import ZodiacStructDark from '../images/Svg/zodiac-struct-dark.svg'

type LesMaisonsPageProps = {
  theme: string
}

function LesMaisonsPage({ theme }: LesMaisonsPageProps): JSX.Element {
  const [swLoaded, setSwLoaded] = useState(false)

  useEffect(() => {
    setTimeout(function() {
      setSwLoaded(true)
    }, 100)
  }, [])

  return (
    !swLoaded ? <Loading /> : <LesMaisonsStyled>
      <AstroLayout>
        {/* Les maisons Here */}
        <Title title={'Les'} span={'maisons'}/>
        {/* Les maisons Here */}

        {/* Les douze Maisons */}
        <InnerLayout className="astro-section">
          <div className="info-part">
            <DbTexte
              titreCall="LES_DOUZE_MAISONS"
              lien={LES_MAISONS}
              theme={theme}
            />
          </div>
          <ZodiacSphereLocale theme={theme} />
        </InnerLayout>
        {/* Fin Les douze Maisons */}
        {/* Les douze Maisons */}
        <InnerLayout className="astro-section">
          <img
            className="image-part"
            src={theme === 'light-mode' ? ZodiacStructLight : ZodiacStructDark}
            alt="Structure de la sphère locale"
          />
          <div className="info-part">
            <DbTexte
              titreCall="LA_DISPOSITION_DE_LA_SPHERE_LOCALE"
              lien={LES_MAISONS}
              theme={theme}
            />
          </div>
        </InnerLayout>
        {/* Fin Les douze Maisons */}
        {/* Position du soleil et Maison */}
        <InnerLayout className="astro-section-sans-image">
          <div className="info-part">
            <DbTexte
              titreCall="POSITION_DU_SOLEIL_ET_MAISON"
              lien={LES_MAISONS}
              theme={theme}
            />
          </div>
        </InnerLayout>
        {/* Fin Position du soleil et Maison */}
        {/* Tableau Schéma binaire */}
        <LesMaisonsSchemaBinaireOppositions />
        {/* Fin Tableau Schéma binaire */}
        {/* Structure de la sphère locale. */}
        <InnerLayout className="astro-section">
          <img
            className="image-part"
            src={theme === 'light-mode' ? ZodiacSphereLocaleLight : ZodiacSphereLocaleDark}
            alt="Structure de la sphère locale"
          />
          <div className="info-part">
            <h4>Structure de la sphère locale</h4>
            <p>
              Ceci concerne l'astrologie selon la méthode Placidus (XVII siècle).
            </p>
            <br/>
            <p>
              Suivant une structure ternaire les Maisons astrologique se divisent en:
              <br/><br/>
              <ul>
                <li><b>Cardinales (ou angulaire)</b><br/>I, IV, VII, X. Dynamisme<br/><br/></li>
                <li><b>Cadentes (ou mutable)</b><br/>III, VI, IX, XII. Modifications<br/><br/></li>
                <li><b>Succedentes</b><br/>I, V, VIII, XI. Possessions</li>
              </ul>
            </p>
            <br/>
            <p>
              Des Maisons cardinales aux succédentes on passe de l'origine au terme, avec en intermédiaire,
              la liaison des maisons cadentes.
            </p>
            <br/>
            <p>
              Les Maisons cardinales sont principales dans la mesure où « la puissance » prime « l'acte » qui
              en découle (représenté par les Maisons succédentes). Quant aux Maisons cadentes elle interfalent
              toutes les fluctuations des unes aux autres.
            </p>
            <br/>
            <p>
              Tout ces points se résorbent dans une vision unitaire du <span className="span-color">JE</span>
              &nbsp;établi au centre de la carte du ciel, centre du monde, entre le&nbsp;
              <span className="span-color">moi (Asc)</span> et le&nbsp;
              <span className="span-color">toi (Desc)</span>. Le « maître du champ » est le&nbsp;
              <span className="span-color">Soi</span> ou <span className="span-color">l'Être sans ego</span>,
              qui « est celui qui est » selon l'UNité de l'UNivers.
            </p>
          </div>
        </InnerLayout>
        {/* Structure de la sphère locale End Here */}
      </AstroLayout>
    </LesMaisonsStyled>
  )
}

const LesMaisonsStyled = styled.div`
`

export default LesMaisonsPage
